<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M30.549,23.105c-0.205,0-0.41,0-0.614-0.001c-0.588,0.15-1.17,0.341-1.766,0.443   c-5.628,0.964-9.123,5.381-8.716,11.081c0.257,3.586,1.601,6.854,3.098,10.058c2.941,6.295,6.608,12.169,10.489,17.916   c0.13,0.194,0.313,0.352,0.547,0.61c0.102-0.275,0.184-0.406,0.197-0.543c0.156-1.708,0.332-3.416,0.447-5.128   c0.437-6.499,0.646-13.002,0.02-19.5C33.754,32.885,33.039,27.772,30.549,23.105z"/>
            <path class="st0" d="M51.835,34.806c0.517-5.13-2.45-9.577-7.406-10.944c-1.029-0.283-2.075-0.506-3.113-0.757   c-0.154,0-0.308,0-0.461-0.001c-0.411,0.821-0.888,1.615-1.223,2.466c-1.601,4.068-2.235,8.348-2.605,12.665   c-0.695,8.096-0.296,16.18,0.459,24.253c0.019,0.203,0.106,0.4,0.168,0.623c0.172-0.063,0.262-0.07,0.306-0.118   c0.119-0.132,0.224-0.277,0.324-0.424c3.83-5.661,7.435-11.457,10.364-17.645C50.173,41.703,51.472,38.41,51.835,34.806z"/>
            <path class="st0" d="M0.838,50.815c-0.196,0.863-0.392,1.726-0.588,2.589c0,0.821,0,1.641,0,2.462   c0.056,0.246,0.127,0.489,0.166,0.738c0.988,6.323,6.362,10.941,12.773,10.952c4.994,0.01,9.987,0,14.981-0.004   c0.269,0,0.537-0.025,0.823-0.04C28.674,66.346,3.532,51.477,0.838,50.815z"/>
            <path class="st0" d="M232.403,37.716c0,8.151,0,16.186,0,24.301c1.9,0,3.762,0,5.625,0c0-8.1,0-16.2,0-24.301   C236.166,37.716,234.303,37.716,232.403,37.716z"/>
            <path class="st0" d="M42.545,69.553c3.888,2.63,7.61,5.537,12.151,7.076c0.616,0,1.231,0,1.845,0.001   c0.167-0.057,0.33-0.126,0.5-0.168c3.58-0.89,5.698-3.459,7.591-6.523c-7.433-0.254-14.712-0.503-21.991-0.752   C42.609,69.309,42.577,69.431,42.545,69.553z"/>
            <path class="st0" d="M6.722,69.927c0.172,0.307,0.284,0.529,0.416,0.738c1.848,2.928,4.214,5.187,7.724,5.964   c0.512,0,1.025,0,1.538,0c4.684-1.421,8.414-4.454,12.373-7.1c-0.034-0.121-0.069-0.243-0.103-0.364   C21.387,69.418,14.104,69.671,6.722,69.927z"/>
            <path class="st0" d="M199.814,37.724c-0.585-0.016-0.84,0.155-1.01,0.729c-1.161,3.927-2.36,7.843-3.55,11.762   c-0.608,2.004-1.224,4.006-1.894,6.199c-0.175-0.52-0.291-0.851-0.397-1.185c-1.758-5.564-3.526-11.126-5.255-16.7   c-0.196-0.632-0.478-0.823-1.12-0.804c-1.587,0.048-3.179,0.059-4.765-0.004c-0.698-0.028-0.966,0.195-1.144,0.856   c-1.524,5.66-3.084,11.31-4.636,16.963c-0.061,0.22-0.149,0.431-0.286,0.821c-0.768-2.352-1.474-4.513-2.178-6.675   c-1.23-3.772-2.452-7.546-3.703-11.311c-0.083-0.249-0.385-0.6-0.593-0.606c-1.79-0.048-3.582-0.028-5.492-0.028   c0.139,0.415,0.232,0.704,0.335,0.987c1.206,3.347,2.413,6.694,3.62,10.041c1.52,4.213,3.034,8.429,4.571,12.636   c0.087,0.24,0.348,0.591,0.534,0.595c2.123,0.042,4.247,0.027,6.404,0.027c1.621-5.992,3.229-11.935,4.837-17.878   c0.229,0.235,0.322,0.485,0.401,0.74c1.695,5.478,3.395,10.953,5.069,16.437c0.159,0.519,0.361,0.734,0.938,0.72   c1.768-0.042,3.537-0.042,5.304,0c0.592,0.014,0.831-0.18,1.016-0.737c2.523-7.604,5.073-15.199,7.615-22.797   c0.079-0.235,0.145-0.474,0.236-0.773C202.988,37.739,201.4,37.768,199.814,37.724z"/>
            <path class="st0" d="M6.577,38.811c-2.193,4.466-1.413,9.231,2.148,12.416c1.636,1.463,3.36,2.847,5.155,4.11   c5.118,3.6,10.636,6.525,16.194,9.375c0.185,0.095,0.399,0.132,0.6,0.196c0.06-0.064,0.12-0.127,0.179-0.191   c-5.288-11.485-11.924-22.146-19.7-32.132C9.586,33.949,7.689,36.545,6.577,38.811z"/>
            <path class="st0" d="M62.608,51.216c2.737-2.64,3.894-5.825,3.088-9.616c-0.533-2.509-1.806-4.655-3.362-6.645   c-0.648-0.83-1.376-1.598-2.12-2.454c-7.844,10.1-14.482,20.73-19.748,32.193c0.068,0.072,0.137,0.143,0.205,0.214   c0.243-0.096,0.5-0.168,0.728-0.291c3.24-1.749,6.513-3.439,9.703-5.275C55.184,56.993,59.192,54.51,62.608,51.216z"/>
            <path class="st0" d="M104.901,47.531c-4.17,0-8.229,0-12.364,0c0-3.274,0-6.491,0-9.748c-1.855,0-3.634,0-5.444,0   c0,8.093,0,16.145,0,24.192c1.84,0,3.619,0,5.475,0c0-3.374,0-6.693,0-10.054c4.137,0,8.196,0,12.369,0c0,3.374,0,6.711,0,10.068   c1.868,0,3.624,0,5.405,0c0-8.092,0-16.142,0-24.2c-1.82,0-3.576,0-5.441,0C104.901,41.07,104.901,44.305,104.901,47.531z"/>
            <path class="st0" d="M218.137,37.757c-1.148,0.015-2.307,0.104-3.44,0.291c-4.382,0.722-7.245,3.167-8.28,7.557   c-0.643,2.725-0.729,5.485-0.177,8.236c0.733,3.65,2.82,6.233,6.353,7.463c1.259,0.438,2.65,0.639,3.989,0.677   c3.456,0.098,6.917,0.045,10.376,0.048c0.146,0,0.291-0.04,0.438-0.062c0-1.382,0-2.723,0-4.123c-2.157,0-4.254,0.015-6.351-0.005   c-1.485-0.015-2.978,0.012-4.451-0.136c-3.062-0.307-4.849-2.725-4.359-5.874c5.041,0,10.098,0,15.167,0c0-1.44,0-2.783,0-4.214   c-5.089,0-10.123,0-15.15,0c-0.181-2.419,1.193-4.604,3.384-5.243c0.966-0.282,2.014-0.37,3.028-0.39   c2.638-0.052,5.278-0.016,7.917-0.019c0.271,0,0.543-0.023,0.843-0.037c0-1.411,0-2.738,0-4.183   C224.276,37.743,221.207,37.717,218.137,37.757z"/>
            <path class="st0" d="M156.143,38.213c-0.104-0.224-0.483-0.445-0.74-0.452c-1.537-0.042-3.076,0.007-4.612-0.036   c-0.555-0.016-0.8,0.191-1.019,0.673c-3.47,7.619-6.959,15.229-10.441,22.842c-0.104,0.227-0.186,0.463-0.314,0.787   c1.894,0,3.705,0.013,5.515-0.022c0.162-0.003,0.382-0.291,0.467-0.492c0.658-1.557,1.304-3.118,1.916-4.693   c0.168-0.431,0.401-0.568,0.85-0.566c3.408,0.016,6.817,0.019,10.225-0.002c0.5-0.003,0.711,0.19,0.887,0.63   c0.618,1.544,1.266,3.077,1.939,4.599c0.101,0.227,0.391,0.521,0.599,0.526c1.869,0.041,3.738,0.024,5.682,0.024   c-0.05-0.182-0.06-0.257-0.091-0.325C163.392,53.872,159.781,46.036,156.143,38.213z M148.959,51.9   c1.272-3.089,2.514-6.105,3.847-9.343c1.371,3.197,2.667,6.218,4.008,9.343C154.119,51.9,151.594,51.9,148.959,51.9z"/>
            <path class="st0" d="M137.828,37.782c-1.83,0-3.609,0-5.48,0c0,0.338,0,0.637-0.002,0.937c0,4.433,0.007,8.866-0.006,13.299   c-0.002,0.767-0.044,1.539-0.14,2.299c-0.287,2.281-1.416,3.576-3.688,3.875c-1.426,0.188-2.92,0.162-4.348-0.03   c-2.045-0.275-3.155-1.493-3.486-3.544c-0.142-0.88-0.182-1.784-0.186-2.677c-0.018-4.434-0.008-8.867-0.008-13.3   c0-0.274,0-0.548,0-0.847c-1.871,0-3.647,0-5.485,0c0,2.175-0.015,4.298,0.003,6.42c0.031,3.663,0.049,7.326,0.137,10.988   c0.058,2.437,1.281,4.222,3.386,5.393c0.689,0.383,1.422,0.716,2.173,0.954c2.531,0.801,5.138,0.864,7.761,0.676   c2.013-0.144,3.956-0.544,5.731-1.563c1.935-1.11,3.225-2.674,3.446-4.963c0.11-1.146,0.215-2.297,0.221-3.447   c0.025-4.561,0.011-9.123,0.009-13.684C137.866,38.318,137.842,38.069,137.828,37.782z"/>
            <path class="st0" d="M70.428,50.63c-9.629,5.308-19.14,10.541-28.209,16.523c0.03,0.089,0.06,0.179,0.09,0.268   c0.243,0.013,0.486,0.039,0.729,0.039c3.56,0.002,7.121,0.005,10.681-0.002c2.049-0.003,4.12,0.172,6.141-0.071   c6.941-0.833,12.051-7.159,11.029-14.691C70.799,52.03,70.598,51.379,70.428,50.63z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'huawei-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
